export default {
  mounted() {
    const metaTag = document.createElement("meta");
    metaTag.name = "referrer";
    metaTag.content = "no-referrer";
    document.getElementsByTagName("head")[0].appendChild(metaTag);
  },
  beforeDestroy() {
    const metaTag = document.querySelector('meta[name="referrer"]');
    if (metaTag) {
      document.getElementsByTagName("head")[0].removeChild(metaTag);
    }
  },
};
